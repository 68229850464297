import * as msal from "@azure/msal-browser";

// Configuración de MSAL
const msalConfig = {
  auth: {
    clientId: "43eb49df-54a7-499e-9191-61d9a12aeac3",
    authority: "https://login.microsoftonline.com/35595a02-4d6d-44ac-99e1-f9ab4cd872db",
    redirectUri: "https://testing.ethicsglobal.com/auth-callback",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

// Crear instancia de MSAL
const msalInstance = new msal.PublicClientApplication(msalConfig);

// Asegurarse de que la instancia de MSAL esté inicializada
let msalInitialized = false;

const initializeMsal = async () => {
  if (!msalInitialized) {
    await msalInstance.initialize();
    msalInitialized = true;
  }
};

export const handleAuthenticationCallback = async (code, state) => {
  try {
    // Asegurarse de que MSAL esté inicializado
    await initializeMsal();

    // Procesar la respuesta de autenticación
    const response = await msalInstance.handleRedirectPromise();

    // Si no hay respuesta, intenta adquirir el token con el código
    if (!response && code) {
      const result = await msalInstance.acquireTokenByCode({
        code: code,
        scopes: ["openid", "profile", "email", "User.Read"],
      });

      console.log("Usuario autenticado:", result.account);
      sessionStorage.setItem("accessToken", result.accessToken);
      sessionStorage.setItem("userAccount", JSON.stringify(result.account));

      return result;
    }

    return response;
  } catch (error) {
    console.error("Error durante la autenticación:", error);
    throw error;
  }
};

export const signIn = async () => {
  try {
    await initializeMsal();
    const loginRequest = {
      scopes: ["openid", "profile", "email", "User.Read"],
    };
    await msalInstance.loginRedirect(loginRequest);
  } catch (error) {
    console.error("Error al iniciar sesión:", error);
  }
};

export const signOut = async () => {
  try {
    await initializeMsal();
    const logoutRequest = {
      account: msalInstance.getActiveAccount(),
    };
    await msalInstance.logout(logoutRequest);
  } catch (error) {
    console.error("Error al cerrar sesión:", error);
  }
};