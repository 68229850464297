import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleAuthenticationCallback } from './AuthAd'; // Asegúrate de que la ruta sea correcta

const AuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleCallback = async () => {
      const urlParams = new URLSearchParams(location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');

      try {
        const result = await handleAuthenticationCallback(code, state);
        if (result) {
          navigate('/dashboard'); // O a donde quieras redirigir después del login exitoso
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error('Error durante la autenticación:', error);
        navigate('/login', { state: { error: 'Falló la autenticación' } });
      }
    };

    handleCallback();
  }, [location, navigate]);

  return <div>Procesando autenticación...</div>;
};

export default AuthCallback;