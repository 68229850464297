import React from "react";
import { Modal } from "react-bootstrap";

// Getting the file name from a given URL
const getFileNameFromURL = (url) => {
  // Split the URL by '/' to get an array of parts
  const urlParts = url.split("/");
  // Get the last part (which should be the file name)
  var fileName = urlParts[urlParts.length - 1];
  // If the file name contains query parameters, remove them
  const queryIndex = fileName.indexOf("?");
  if (queryIndex !== -1) {
    fileName = fileName.substring(0, queryIndex);
  }
  return fileName;
};
// Modal showing previously uploaded evidence attachments
export const ModalAttachments = ({
  attachments,
  show,
  onHide,
  no_attachments,
}) => {
  // console.log(attachments);
  return (
    <Modal show={show} onHide={onHide} centered>
      {attachments?.length === 0 ? (
        <span className="text-center">{no_attachments}</span>
      ) : (
        <ul>
          {attachments?.map((attachment) => (
            <li key={attachment.id}>
              <a
                href={attachment.file}
                target="_blank"
                rel="noopener noreferrer"
              >
                {attachment.thumb ? (
                  <img width={80} src={attachment.thumb} alt="Thumbnail" />
                ) : (
                  <span>{getFileNameFromURL(attachment.name)}</span>
                )}
              </a>
            </li>
          ))}
        </ul>
      )}
    </Modal>
  );
};
